import React from "react"
import { graphql } from "gatsby"
import Blogcard from "../components/blogcard"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Colors from "../constants/Colors"
import { rhythm } from "../utils/typography"
import { FaSearch } from "react-icons/fa"
import { motion } from "framer-motion"

class BlogIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    const filterByValue = (array, results) => {
      return array.filter(function(edges) {
        if (results.length < 1) {
          return array
        }
        return results.map(a => a.title).includes(edges.node.frontmatter.title)
      })
    }

    const getSearchResults = query => {
      if (!query || !window.__LUNR__) return []
      const lunrIndex = window.__LUNR__["da"]
      const results = lunrIndex.index.search(query) // you can  customize your search , see https://lunrjs.com/guides/searching.html
      return results.map(({ ref }) => lunrIndex.store[ref])
    }

    const search = event => {
      const query = event.target.value
      const results = getSearchResults(query)
      this.setState(s => {
        return {
          results,
          query,
        }
      })
    }
    console.log(data)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} />
        <Bio />
        {/* <input
          type="text"
          value={this.state.query}
          onChange={search}
          placeholder={"søg efter artikler"}
          style={textInput}
        /> */}
        <motion.div
          className="container"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {this.state.query === "" &&
            posts.map(({ node }) => (
              <motion.div
                key={node.frontmatter.title}
                variants={item}
              >
                <div class="post">
                    <a href={node.fields.slug}><img style={img}
                      src={
                        node.frontmatter.featuredImage.childImageSharp.fluid.src
                      }
                    ></img></a>
                    <a href={node.fields.slug}>
                      <h3 style={postTitle}>{node.frontmatter.title || node.fields.slug}</h3>
                    </a>
                </div>
              </motion.div>
            ))}
          {/* {this.state.query !== "" &&
            filterByValue(posts, this.state.results).map(({ node }) => (
              <motion.li
                key={node.frontmatter.title}
                className="hex"
                variants={item}
              >
                <div class="hexIn">
                  <a class="hexLink" href={node.fields.slug}>
                    <h1 
                      data-tooltip={node.frontmatter.title || node.fields.slug}
                    ></h1>
                    <img 
                      src={
                        node.frontmatter.featuredImage.childImageSharp.fluid.src
                      }
                    ></img>
                    <p></p>
                  </a>
                </div>
              </motion.li>
            ))} */}
        </motion.div>
      </Layout>
    )
  }
}

const textInput = {
  boxSizing: "border-box",
  fontSize: 18,
  background: "rgb(233, 233, 233) none repeat scroll 0% 0%",
  color: "rgb(102, 92, 92)",
  border: "none",
  outline: "none",
  width: "12.5%",
  fontWeight: "normal",
  borderRadius: 5,
  marginBottom: rhythm(2 / 3),
  top: "55.9804%",
  left: "25.6595%",
  zIndex: 5,
  textAlign: "center",
}

const searchIcon = {
  color: Colors.lightGrey,
  position: "absolute",
  left: 158,
  top: 213,
}

const deleteIcon = {
  color: Colors.lightGrey,
  position: "absolute",
  right: 80,
  top: 15,
  cursor: "pointer",
}

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const postTitle = {
  fontSize:'18px',
  paddingLeft:'10px',
  color:'#FDFDFD'
}

const img = {
  height:'250px',
  width:'370px',
  objectFit:'cover',
  borderRadius:'15px',
  marginBottom:'0.6666rem'
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { ne: "short" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxHeight: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
