import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import Colors from "../constants/Colors"
import Variables from "../constants/Variables"
import Img from "gatsby-image"

const Blogcard = props => {
  return (
    <div className="cell cell-1" style={CardContainer}>
      <div style={card} className="card elevation">
        <div key={props.node.fields.slug}>
          <h3 className="blog-card-header" style={{ cardTitle }}>
            <Link
              className="blog-card-link"
              style={{ boxShadow: `none` }}
              to={props.node.fields.slug}
            >
              {props.title}
            </Link>
          </h3>
          <p className="blog-card-date" style={cardSubtitle}>
            {props.node.frontmatter.date}
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: props.node.frontmatter.description || props.node.excerpt,
            }}
          />
        </div>
      </div>
      <div style={ImageCard} className="card elevation">
        <Img
          className="img"
          style={ImageStyle}
          fluid={props.node.frontmatter.featuredImage.childImageSharp.fluid}
        />
      </div>
    </div>
  )
}

const card = {
  backgroundColor: Colors.white,
  padding: 25,
  borderRadius: 15,
  display: "grid",
  gridTemplateColumns: "3fr 1fr",
}

const cardTitle = {
  color: Colors.almostBlack,
  fontWeight: Variables.fontWeightHeadline,
  fontSize: 20,
  lineHeight: 1,
  marginBottom: rhythm(1 / 8),
}

const cardSubtitle = {
  color: Colors.grey,
}

const ImageContainer = {
  margin: rhythm(1 / 8),
  textAlign: "center",
  whiteSpace: "nowrap",
  maxHeight: "100%",
  maxWidth: "100%",
  position: "relative",
  top: -20,
}

const Helper = {
  display: "inline-block",
  height: "100%",
  verticalAlign: "middle",
}

const ImageStyle = {
  display: "flex",
  maxHeight: "100%",
  objectFit: "contain",
  borderRadius: 5,
  overflow: "hidden",
  backgroundSize: "cover",
  backgroundPosition: "center",
}

const ImageCard = {
  verticalAlign: "middle",
  display: "block",
  maxHeight: "100%",
  borderRadius: 5,
  backgroundPosition: "top 20px",
}

const CardContainer = {
  backgroundColor: Colors.white,
  border: "1px solid #E8EAED",
  display: "grid",
  gridTemplateColumns: "3fr 1fr",
  borderRadius: 15,
  marginBottom: 20,
}

export default Blogcard
